import React from 'react'
import './App.css'

const App = () => (
  <div className="app">
    <header className="header">
      <div>
        <h1>Dylan Ott</h1>
        <p>M&T @ Penn</p>
        <p>Building things that I find interesting.</p>
      </div>
      <br />
      <div>
        <a href="mailto:dylanott@wharton.upenn.edu">dylanott@wharton.upenn.edu</a>
        <br /><br />
        310 South 36th Street<br />
        Philadelphia, PA 19104-6023<br />
      </div>
    </header>
    <main className="content">
      <section className="column">
        <h2>Current Projects</h2>
        <br />
        <p>
          <a href="https://kolly.ai" target="_blank" rel="noopener noreferrer">Kolly</a><br />
          <a href="https://nivaro.io" target="_blank" rel="noopener noreferrer">Nivaro</a>
        </p>
        <br />
        <h2>Past Projects</h2>
        <br />
        <p>
          <a href="https://www.linkedin.com/posts/ott-dylan_ai-computervision-ml-activity-7260302026876547072-1N9_?utm_source=share&utm_medium=member_desktop&rcm=ACoAADgrIDwB0Q8JQTyxg_hsMqtKsUlQbafBIyc" target="_blank" rel="noopener noreferrer">AI blackjack glasses</a><br />
          <a href="https://clara.so" target="_blank" rel="noopener noreferrer">Clara</a><br />
          <a href="https://delilah.ai" target="_blank" rel="noopener noreferrer">Delilah</a><br />
          <a href="https://scholarai.org" target="_blank" rel="noopener noreferrer">ScholarAI</a>
          <br />
          <a href="https://www.figma.com/design/7LNvxryrgnzREOHLCtlPde/Lester-the-Lawyer?node-id=0-1&t=pU9tYtJ0dVvDDCQu-1" target="_blank" rel="noopener noreferrer">Lester the Lawyer</a>
          <br />
        </p>
      </section>

      <section className="column">
        <h2>Socials</h2>
        <br />
        <p>
          <a href="https://linkedin.com/in/ott-dylan" target="_blank" rel="noopener noreferrer">LinkedIn</a><br />
          <a href="https://x.com/dylan_ott18" target="_blank" rel="noopener noreferrer">Twitter</a><br />
          <a href="https://tiktok.com/@dylanott" target="_blank" rel="noopener noreferrer">TikTok</a><br />
          <a href="https://github.com/ott-dylan" target="_blank" rel="noopener noreferrer">GitHub</a> <br />
          <a href="https://www.instagram.com/d0college/" target="_blank" rel="noopener noreferrer">Instagram</a> <br />
          <a href="https://www.youtube.com/@dylan_ott" target="_blank" rel="noopener noreferrer">YouTube</a>
        </p>
      </section>
    </main>
    
  </div>
)

export default App